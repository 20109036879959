import axios from "axios";
import authHeader from "./auth-header";

const API_URL = process.env.VUE_APP_API_URL;

class AuthService {
  async login(user) {
    const response = await axios.post(
      "https://server.varsanpr.com/api/auth/login",
      {
        email: user.username,
        password: user.password,
      }
    );
    if (response.data.token) {
      let user = response.data;
      if (!user.permissions.includes("portals-admin")) {
        localStorage.removeItem("user");
        window.location.href = "https://gateway.varsanpr.com";
        return;
      }
      localStorage.setItem(
        "user",
        JSON.stringify({
          permissions: user.permissions,
          ...user.user,
          clients: user.clients,
          token: user.token,
        })
      );
    }
    return response.data;
  }

  async checkToken(token) {
    const response = await axios.get("https://server.varsanpr.com/api/auth", {
      headers: {
        "x-vars-version": 2,
        Authorization: "Bearer " + token,
      },
    });
    if (response.data.user) {
      let user = response.data;
      if (!user.permissions.includes("portals-admin")) {
        localStorage.removeItem("user");
        window.location.href = "https://gateway.varsanpr.com";
        return;
      }
      localStorage.setItem(
        "user",
        JSON.stringify({
          permissions: user.permissions,
          ...user.user,
          clients: user.clients,
          token: token,
        })
      );
    }
    return response.data;
  }

  async logout() {
    return axios
      .post(
        `https://api.varsanpr.com/api/auth`,
        {},
        {
          headers: authHeader(),
        }
      )
      .then((response) => {
        localStorage.removeItem("user");
        localStorage.removeItem("token");
        window.location.href = "https://gateway.varsanpr.com";
      })
      .catch((error) => {
        localStorage.removeItem("user");
        localStorage.removeItem("token");
        window.location.href = "https://gateway.varsanpr.com";
      });
  }

  getQR() {
    return axios.get("https://server.varsanpr.com/api/auth/2fa", {
      headers: authHeader(),
    });
  }

  verify2fa(otp) {
    return axios.post(
      "https://server.varsanpr.com/api/auth/2fa",
      { otp: otp },
      { headers: authHeader() }
    );
  }
}

export default new AuthService();
